//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CheekyMonster from '~/assets/img/monster.svg';
import VisitMyShop from '~/assets/img/visit-my-shop.svg';
import head from '~/assets/js/head';
import { SITE_DESCRIPTION, SITE_TITLE } from '~/assets/js/constants';

export default {
  components: {
    CheekyMonster,
    VisitMyShop,
  },

  layout: 'index',

  data() {
    return {
      services: [
        'Character Design',
        'Brand Identity',
        'Illustration',
        'Iconography',
        'Web Experiences',
        'Event Design',
        'Teaching & Speaking',
        'Workshops',
        'Creative Strategy',
        'Packaging',
        'Concept & Ideation',
        'Custom Lettering',
      ],
    };
  },

  head() {
    return head({
      description: SITE_DESCRIPTION,
      title: SITE_TITLE,
    });
  },
};
